import React from "react";
// import HeroImage from "../images/heroBg.webp";
import { ReactComponent as AppStore } from "../icons/AppStore.svg";
import { ReactComponent as GooglePlay } from "../icons/GooglePlay.svg";

const Hero = () => {
  return (
    <div className="relative flex items-end justify-center w-full py-4 sm:justify-end sm:items-center hero-background">
      <div
        className="absolute inset-0"
        style={{
          background:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), black)",
        }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-20"></div>
      <div className="relative flex flex-col items-center w-full max-w-screen-xl gap-16 p-4 mx-auto sm:items-end">
        <div className="flex flex-col items-center max-w-sm gap-1 text-center sm:items-start sm:text-left">
          <p className="text-lg font-semibold uppercase text-primary">
            TRY IT FREE
          </p>
          <h1 className="text-6xl font-extrabold font-condensed leading-12">
            All In One Fitness App
          </h1>
          <p className="mt-1 text-2xl text-secondary-100 max-w-screen-xs">
            The #1 app for fitness and personal training, powered with Stridez
            AI.
          </p>
          <div className="flex mt-4 gap-x-3 gap-y-2" id="download">
            <a
              href="https://apps.apple.com/app/stridez-fitness/id6504737745?platform=iphone"
              target="blank"
            >
              <AppStore
                style={{ height: "3.25rem" }}
                className="flex-none w-full"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.modular.stridez" target="blank">
              <GooglePlay
                style={{ height: "3.25rem" }}
                className="flex-none w-full"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
