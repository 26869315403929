import React from "react";
import { ReactComponent as CheckIcon } from "../icons/check.svg";
import { ReactComponent as DiscordIcon } from "../icons/discord.svg";
import pricingHeader from "../images/womanStretching.webp";
import Gem2 from "../images/2Premium.webp";
import Gem3 from "../images/3Premium+.webp";
import Gem4 from "../images/4Lifetime.webp";
import Card from "../components/Card";
import CachedImage from "../components/CachedImage";

const Plans = () => {
  return (
    <main className="mb-24">
      <div className="relative w-full overflow-hidden lg:h-600 h-500">
        <div className="overlay_single-header-gradient" />
        <div className="overlay_single-header" />
        <CachedImage
          className="pricing_header-image"
          src={pricingHeader}
          alt=""
        />
      </div>
      <div className="relative z-20 flex flex-col items-center px-6 -mt-24 lg:px-8">
        <p className="text-lg font-semibold uppercase text-primary">
          TRY IT FREE
        </p>
        <div className="flex flex-col max-w-md gap-4 text-center">
          <h1 className="text-5xl font-extrabold font-condensed">
            Discover Our Plans
          </h1>
          <p className="text-lg leading-normal text-secondary-400">
          Transform Your Body and Life with AI-Powered Fitness - Start Free Today!
          </p>
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-6 gap-y-8 gap-x-4 lg:mt-12 lg:items-stretch lg:flex-row">
          <Card className="flex flex-col w-full gap-4 px-5 py-6 max-w-80">
            <div className="flex flex-col items-center gap-2 xs:-ml-8">
              <div className="flex flex-col items-center gap-3 text-center xs:flex-row">
                <CachedImage src={Gem2} alt="" className="size-24" />
                <h3 className="text-3xl font-bold leading-none font-condensed">
                  Premium
                </h3>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4">
              <div className="flex flex-col gap-2">
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Ad-Free</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">All Plans</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">30+ Sports</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">All Exercises</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">70+ Workouts</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Calorie Tracker</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Progress Tracker</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <DiscordIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Role - Fire</div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="relative flex flex-col items-center w-full gap-4 px-5 py-6 max-w-80">
            <div className="absolute flex items-center justify-center px-4 py-3 mx-auto font-medium leading-none border rounded-full -top-5 bg-gradient-to-br from-gradient-start to-gradient-end border-secondary-500">
              Most Popular
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="flex flex-col items-center gap-3 text-center xs:-ml-8 xs:flex-row">
                <CachedImage src={Gem3} alt="" className="size-24" />
                <h3 className="text-3xl font-bold leading-none font-condensed">
                  Premium+
                </h3>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-3 text-secondary-300">
                  <div className="text-lg leading-tight">
                    Everything in <strong>Premium</strong> plus:
                  </div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">All Sports</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">All Workouts</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">
                    AI Personalized Meals
                  </div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">
                    AI Personalized Workouts
                  </div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <DiscordIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Exclusive Room</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <DiscordIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Role - Water</div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="flex flex-col w-full gap-4 px-5 py-6 max-w-80">
          <div className="flex flex-col items-center gap-2">
              <div className="flex flex-col items-center gap-3 text-center xs:-ml-8 xs:flex-row">
                <CachedImage src={Gem4} alt="" className="size-24" />
                <h3 className="text-3xl font-bold leading-none font-condensed">
                  Lifetime
                </h3>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-3 text-secondary-300">
                  <div className="text-lg leading-tight">
                    Everything in <strong>Premium+ </strong>plus:
                  </div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Lifetime Updates</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">
                    Early Feature Access
                  </div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <CheckIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Priority Support</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <DiscordIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Elite Room</div>
                </div>
                <div className="flex items-start gap-3 text-secondary-300">
                  <DiscordIcon className="flex-none size-6" />
                  <div className="text-lg leading-tight">Role - Air</div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
};

export default Plans;
