import React, { useRef, useState } from "react";
import Button from "./Button";
import TextInput from "./TextInput";
import { XANO_BASE } from "../config";

export default function ContactUs() {
  const form = useRef();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string?.slice(1);
  }
  
  async function sendEmail(e) {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const formData = new FormData(form.current);
      let formObject = {};
      formData.forEach((value, key) => {
        formObject[key] = value;
      });
      let content = "";
      for (const key in formObject) {
        content += `${capitalizeFirstLetter(key)}: ${formObject[key]}\n`;
      }
      const response = await fetch(`${XANO_BASE}/send_email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });
      if (!response.ok) {
        throw new Error("Failed to send email.");
      }
      form.current.reset();
      setError("");
    } catch (error) {
      setError("Failed to send email. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-6">
      <TextInput label="Name" name="name" placeholder="John Doe" />
      <TextInput label="Email" name="email" placeholder="john@email.com" />
      <div className="flex flex-col">
        <label htmlFor="message" className="mb-2 font-medium">
          Message
        </label>
        <textarea
          id="message"
          name="message"
          placeholder="How can we help you?"
          className="form_input"
          style={{ minHeight: "8rem", paddingTop: ".75rem" }}
        />
      </div>
      <div className="w-full">
        <Button type="submit" className="w-full" disabled={isLoading} isLoading={isLoading}>
          Send Email
        </Button>
        {error && <div className="mt-1 text-sm text-red-600">{error}</div>}
      </div>
    </form>
  );
}
