import React, { useState } from "react";
import Hero from "../components/Hero";
import MainShell from "../components/MainShell";
import SectionHeader from "../components/SectionHeader";
import GridSection from "../components/GridSection";
import Button from "../components/Button";
import dashboardStridez from "../images/stridez-dashboard.webp";
import newsletterBg from "../images/newsletterBg.webp";
import gem2 from "../images/2Premium.webp";
import gem3 from "../images/3Premium+.webp";
import gem4 from "../images/4Lifetime.webp";
import phone from "../images/phone.webp";
import sportsWeb from "../images/sports-web.webp";
import DiscordSection from "../components/DiscordSection";
import TextInput from "../components/TextInput";
import { ReactComponent as GameIcon } from "../icons/game.svg";
import { ReactComponent as StarsIcon } from "../icons/stars.svg";
import { ReactComponent as MacroIcon } from "../icons/macro.svg";
import { ReactComponent as LibraryIcon } from "../icons/library.svg";
import { ReactComponent as AnalyticsIcon } from "../icons/analytics.svg";
import { ReactComponent as StreakIcon } from "../icons/streak.svg";
import { ReactComponent as ChevronIcon } from "../icons/chevron-down.svg";
import { ReactComponent as MailIcon } from "../icons/mail.svg";
import { XANO_BASE } from "../config";
import { scrollTo } from "../js/utils";
import CachedImage from "../components/CachedImage";
import BlogList from "../components/BlogList";
import { useBlogs } from "../js/context/BlogProvider";

const Home = () => {
  const [openItemIds, setOpenItemIds] = useState([]);
  const [email, setEmail] = useState("");
  const [touched, setTouched] = useState(false);
  const { blogs, isLoading, error } = useBlogs();

  const faqs = [
    {
      question: "How does Stridez transform my fitness journey?",
      answer:
        "Stridez revolutionizes your fitness with AI-powered personalization, adapting to your goals and progress in real-time. Our unique gamification system, featuring activity streaks and leaderboards, keeps you motivated. With comprehensive tracking for various activities and nutrition, Stridez becomes your all-in-one fitness companion. Experience the difference – download Stridez now!",
    },
    {
      question: "What makes Stridez's motivation system so effective?",
      answer:
        "Our system combines AI with game-like elements for consistent engagement. Earn points for workouts, maintain streaks, and climb leaderboards to win exclusive merchandise. Users report a 78% increase in workout consistency. Join now and supercharge your motivation!",
    },
    {
      question: "How do Premium and Premium+ enhance my Stridez experience?",
      answer:
        "Premium unlocks 70+ workouts, 30+ sports, all exercises, and removes ads. Premium+ adds AI-personalized workouts and meal plans. Both offer advanced analytics. Users who upgrade report achieving their fitness goals 3x faster. Try Premium free for 7 days!",
    },
    {
      question:
        "How versatile is Stridez for different fitness activities and levels?",
      answer:
        "Stridez adapts to all activities and experience levels, from strength training and cardio to yoga and swimming. Whether you're a beginner or an athlete, Stridez personalizes your experience. 94% of users report Stridez perfectly fits their diverse fitness needs. Explore the possibilities now!",
    },
    {
      question:
        "How does Stridez's progress tracking help me reach my goals faster?",
      answer:
        "Our comprehensive tracking covers workouts, nutrition, body measurements, and performance with easy-to-understand visual charts. This 360° view helps identify improvement areas and celebrate victories. Users leveraging our tracking features reach their goals 40% faster. Start tracking your success today!",
    },
    {
      question: "Is my personal data safe and secure with Stridez?",
      answer:
        "Absolutely. We use bank-level encryption and adhere to the strictest data protection regulations. Your information is never shared without consent. Our commitment to data security has earned us a 4.9/5 trust rating. Feel confident in your fitness journey with Stridez!",
    },
    {
      question: "Can I seamlessly use Stridez across all my devices?",
      answer:
        "Yes! Stridez offers perfect syncing across smartphones, tablets, and web browsers. Your data and progress are always up-to-date, allowing effortless device switching. This flexibility has increased user engagement by 65%. Experience the convenience – try Stridez now!",
    },
    {
      question: "How does Stridez stay fresh and exciting for long-term users?",
      answer:
        "We constantly evolve with regular updates, new features, workouts, and challenges. Premium users get first access to innovations. Our commitment to improvement has resulted in a 92% long-term user retention rate. Join Stridez and be part of the fitness future!",
    },
    {
      question: "What kind of support can I expect as a Stridez user?",
      answer:
        "You'll have access to swift assistance via email and our active Discord community. Premium users enjoy priority support. With a 98% satisfaction rate for our support services, you can trust we've got your back. Ready for unparalleled support? Download Stridez today!",
    },
  ];
  const features = [
    {
      icon: <StarsIcon />,
      title: "AI-Powered Personalization",
      supportText:
        "Custom workouts and meal plans that evolve with you. Our AI adapts to your feedback, ensuring optimal challenge and nutrition.",
    },
    {
      icon: <GameIcon />,
      title: "Gamified Motivation",
      supportText:
        "Earn points, climb leaderboards, and win Stridez merch. Transform your fitness journey into an exciting, rewarding game.",
    },
    {
      icon: <StreakIcon />,
      title: "Streak-Building Success",
      supportText:
        "Maintain consistency with our unique streak system. Freeze or repair streaks to keep your progress on track, no matter what.",
    },
    {
      icon: <MacroIcon />,
      title: "Effortless Macro Mastery",
      supportText:
        "Track nutrition easily—photo, type, or voice input. Instant macro calculations and goal updates visualize your progress.",
    },
    {
      icon: <AnalyticsIcon />,
      title: "Advanced Fitness Analytics",
      supportText:
        "Gain powerful insights into your journey. Track progress, identify trends, and get actionable recommendations to improve.",
    },
    {
      icon: <LibraryIcon />,
      title: "Extensive Exercise Library",
      supportText:
        "Explore diverse workouts with detailed video instructions. Find the perfect moves to elevate your fitness routine.",
    },
  ];

  const subscriptions = [
    {
      imgSrc: gem2,
      planName: "Fire: Premium Plan",
      benefits: [
        "Ad-Free",
        "70+ workouts",
        "Comprehensive exercise libraries",
        "and more",
      ],
    },
    {
      imgSrc: gem3,
      planName: "Water: Premium+ Plan",
      benefits: [
        "All workouts",
        "AI-personalized routines",
        "Exclusive community rooms",
        "and more",
      ],
    },
    {
      imgSrc: gem4,
      planName: "Air: Lifetime Plan",
      benefits: [
        "Lifetime updates",
        "Exclusive merchandise",
        "Early access",
        "and more",
      ],
    },
  ];

  const submitNewsletter = (e) => {
    e.preventDefault();
    fetch(`${XANO_BASE}/newsletter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then(() => {
        setEmail("");
        setTouched(false);
      });
  };

  const handleChevron = (id) => {
    setOpenItemIds(
      openItemIds.includes(id)
        ? openItemIds.filter((itemId) => itemId !== id)
        : [...openItemIds, id]
    );
  };

  return (
    <div className="overflow-x-hidden">
      <Hero />
      <MainShell>
        <div
          className="flex flex-col gap-12 py-16 md:py-24 md:gap-16"
          id="products"
        >
          <div>
            <SectionHeader
              heading="Features"
              subheading="Elevate your fitness journey with our versatile tools and unlock your potential — one stride at a time."
            />
            <div className="grid grid-cols-1 gap-12 py-12 xs:grid-cols-2 md:grid-cols-3">
              {features.map((feature, index) => (
                <div key={index} className="flex flex-col gap-4">
                  <div className="text-white size-10">{feature.icon}</div>
                  <div className="flex flex-col gap-1">
                    <h3 className="text-2xl font-extrabold leading-tight xs:text-3xl font-condensed">
                      {feature.title}
                    </h3>
                    <div className="text-lg leading-normal text-secondary-300">
                      {feature.supportText}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <SectionHeader
              heading="Dive into our world"
              subheading="Innovative solutions that define the future."
            />
            <div className="grid items-center grid-cols-1 gap-12 pt-10 pb-16 text-left md:gap-16 sm:grid-cols-2">
              <div className="flex flex-col items-start">
                <div className="flex flex-col gap-3">
                  <h3 className="text-3xl font-extrabold font-condensed">
                    Access Anywhere, Anytime
                  </h3>
                  <p className="max-w-lg mx-auto text-lg leading-normal md:text-xl text-secondary-300">
                    Experience the complete Stridez app from any browser, on any
                    device. Full mobile functionality, optimized for all
                    screens—track and plan your fitness seamlessly, wherever you
                    are.
                  </p>
                </div>
                <Button
                  className="mt-4"
                  hoverAnimation={true}
                  onClick={() => scrollTo()}
                  variant={"secondary"}
                >
                  Get Fit Now
                </Button>
              </div>
              <div className="relative">
                <CachedImage
                  src={dashboardStridez}
                  alt="dashboard"
                  className="object-cover w-full h-full border-4 rounded-xl border-secondary-400"
                />
                <CachedImage
                  src={phone}
                  alt="phone"
                  className="absolute z-20 object-contain w-1/3 h-auto border-4 rounded-xl -bottom-12 border-secondary-400 right-8"
                />
              </div>
            </div>
            <div className="grid items-center grid-cols-1 gap-12 py-16 text-left md:gap-16 sm:grid-cols-2">
              <div className="relative order-2 sm:order-1">
                <CachedImage
                  src={sportsWeb}
                  alt="dashboard"
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="flex flex-col items-start order-1 md:order-2">
                <div className="flex flex-col gap-3">
                  <h3 className="text-3xl font-extrabold font-condensed">
                    All-in-One Fitness Platform
                  </h3>
                  <p className="max-w-lg mx-auto text-lg leading-normal md:text-xl text-secondary-300">
                    From dynamic workouts to personalized meal plans and
                    interactive exercise guides, Stridez delivers everything you
                    need for your fitness journey in one seamless app.
                  </p>
                </div>

                <Button
                  className="mt-4"
                  hoverAnimation={true}
                  onClick={() => scrollTo()}
                  variant={"secondary"}
                >
                  Explore Now
                </Button>
              </div>
            </div>{" "}
          </div>
          <div className="flex flex-col gap-12 py-8 md:gap-16">
            <SectionHeader
              heading="Discover the Perfect Plan"
              subheading="Choose from range of plans to suit your needs. Whether
            you're a beginner or a pro, we have the perfect plan."
              containerLarge={true}
              className="text-center sm:text-left"
            />
            <div className="grid w-full grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-3">
              {subscriptions.map((subscription, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center gap-4 text-white sm:items-start"
                >
                  <CachedImage
                    src={subscription.imgSrc}
                    alt="gem"
                    loading="lazy"
                    className="flex-none w-32 h-32 sm:-ml-6"
                  />
                  <div className="flex flex-col gap-2 text-center sm:text-left">
                    <p className="text-3xl font-extrabold leading-tight font-condensed">
                      {subscription.planName}
                    </p>
                    <ul className="flex flex-col text-lg text-secondary-200">
                      {subscription.benefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <GridSection />
          <BlogList
            blogs={blogs}
            error={error}
            isLoading={isLoading}
            limit={3}
            heading="Blog"
            subheading="Explore our latest articles"
          />
          <div className="flex flex-col items-center gap-6 py-4">
            <div className="flex flex-col items-center max-w-screen-sm">
              <SectionHeader
                heading="FAQs"
                subheading="Find answers to common questions about Stridez and our services."
                className="text-left xs:text-center"
              />
            </div>
            <div className="flex flex-col items-center w-full max-w-screen-sm mx-auto">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-start w-full h-full gap-2 py-5 text-left border-b border-secondary-700"
                >
                  <button onClick={() => handleChevron(index)}>
                    <div className="flex items-start justify-between gap-6 text-left">
                      <h4 className="text-2xl font-bold leading-tight font-condensed">
                        {faq.question}
                      </h4>
                      <ChevronIcon
                        className={`size-7 flex-none transition-all ease-linear transform text-secondary-200 ${
                          openItemIds.includes(index) ? "rotate-180" : ""
                        }`}
                      />
                    </div>
                  </button>
                  <div
                    className={`${
                      openItemIds.includes(index) ? "block" : "hidden"
                    }`}
                  >
                    <p className="text-lg leading-normal text-secondary-300">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="py-12 mx-auto">
            <DiscordSection />
          </div> */}
        </div>
      </MainShell>

      <div className="relative flex flex-col items-center gap-6 px-6 pt-20 pb-24 overflow-hidden border-t border-secondary-200">
        <div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url(${newsletterBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(2px)",
            transform: "scale(1.1)",
          }}
        />
        <div className="absolute inset-0 z-10 bg-black opacity-70" />
        <div className="relative z-20 flex flex-col items-center justify-center gap-4">
          <MailIcon className="text-white size-14 md:size-16" />
          <SectionHeader
            heading="Join our newsletter"
            subheading="Receive fitness tips, special offers, and updates directly to your inbox."
            className="text-center"
          />
        </div>
        <form
          onSubmit={submitNewsletter}
          className="relative z-20 flex flex-col items-start w-full max-w-sm gap-2 mx-auto xs:flex-row"
        >
          <TextInput
            error={
              touched &&
              !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
            }
            value={email}
            type="email"
            placeholder="Enter your email"
            style={{ backgroundColor: "#2c2c2e" }}
            onChange={(e) => {
              setEmail(e.target.value);
              setTouched(true);
            }}
            errorMessage="* Please enter a valid email address"
          />
          <Button
            className="w-full h-full xs:w-auto"
            disabled={
              !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
            }
            type="submit"
            variant="secondary"
          >
            Subscribe
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Home;
