import React, { useState } from 'react';

const TextInput = ({ label, name, type = 'text', value, onChange, error, errorMessage, placeholder, helper, style, className }) => {
  const [touched, setTouched] = useState(false);

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <div className='w-full'>
      {label && <label htmlFor={name} className='ml-1'>{label}</label>}
      <div className={`relative flex items-center ${label ? "mt-2" : ""}`}>
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          style={style}
          onChange={onChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={`text-lg w-full rounded-md py-2 px-4 placeholder:text-secondary-400 text-white h-12 bg-secondary-900 ${className}`}
          aria-describedby={`${name}-error`}
        />
        {helper && <div className='absolute inset-y-auto right-4'>{helper}</div>}
      </div>
      {touched && error && <div id={`${name}-error`} className="mt-1 ml-1 text-sm text-secondary-300">{errorMessage}</div>}
    </div>
  );
};

export default TextInput;