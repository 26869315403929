import React from "react";
import featureBox from "../images/featureBox.webp";
import featureCycle from "../images/featureCycle.webp";
import featureBaseball from "../images/featureBaseball.webp";
import SectionHeader from "./SectionHeader";

const CoachesGrid = () => {
  return (
    <div className="flex flex-col max-w-screen-xl gap-8 py-8">
      <SectionHeader
        heading="Together We Thrive"
        subheading=" Join a community that inspires and supports your fitness goals"
      />
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="flex flex-col col-span-1 md:col-span-2">
          <div className="grid bg-gray-50 sm:grid-cols-2">
            <div className="relative">
              <img
                src={featureBox}
                alt=""
                className="object-cover aspect-square"
              />
              <div className="absolute inset-0 bg-black opacity-30"></div>
            </div>
            <div className="flex flex-col items-center justify-center w-full p-4 text-center aspect-square">
              <h3 className="mb-2 text-4xl font-extrabold text-black font-condensed">
                Diverse Passions
              </h3>
              <p className="max-w-xs mx-auto text-lg font-medium text-secondary-500">
                From boxing to cycling, baseball to yoga, the Stridez community
                embraces all forms of fitness.
              </p>
            </div>
          </div>
          <div className="grid bg-gray-50 sm:grid-cols-2">
            <div className="relative sm:order-2">
              <img
                src={featureBaseball}
                alt=""
                className="object-cover aspect-square"
              />
              <div className="absolute inset-0 bg-black opacity-30"></div>
            </div>
            <div className="flex flex-col items-center justify-center w-full p-4 text-center xs:order-1 aspect-square">
              <h3 className="mb-2 text-4xl font-extrabold text-black font-condensed">
                Shared Motivation
              </h3>
              <p className="max-w-xs mx-auto text-lg font-medium text-secondary-500">
                Share your progress, join challenges, and motivate each other to
                new heights.
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 bg-gray-50 sm:grid-cols-2 md:grid-cols-1">
          <div className="relative">
            <img
              src={featureCycle}
              alt=""
              className="object-cover aspect-square"
            />
            <div className="absolute inset-0 bg-black opacity-30"></div>
          </div>
          <div className="flex flex-col items-center justify-center w-full p-4 text-center aspect-square">
            <h3 className="mb-2 text-4xl font-extrabold text-black font-condensed">
              Expert Guidance
            </h3>
            <p className="max-w-xs mx-auto text-lg font-medium text-secondary-500">
              Access valuable insights from fitness professionals and
              experienced community members.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachesGrid;
