import React from "react";

const MainShell = ({ children, className }) => {
  return (
    <div className={`w-full px-5 overflow-hidden md:px-6 lg:px-8 ${className}`}>
      <div className={`w-full max-w-screen-xl mx-auto`}>
        {children}
      </div>
    </div>
  );
};

export default MainShell;
