import React, { useState } from "react";

const SocialShare = () => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="flex flex-wrap gap-2 mt-4">
      <button
        onClick={handleCopy}
        className="flex items-center gap-2 px-4 py-2 rounded-md bg-secondary-800 hover:bg-secondary-700 flex-nowrap"
      >
        <svg
          className="w-4 h-4"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2358 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7514 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4062 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997M14 11C13.5706 10.4258 13.0227 9.95078 12.3935 9.60703C11.7643 9.26327 11.0685 9.05885 10.3534 9.00763C9.63822 8.95641 8.92043 9.0596 8.24867 9.31018C7.57691 9.56077 6.9669 9.9529 6.46002 10.46L3.46002 13.46C2.54923 14.403 2.04525 15.666 2.05665 16.977C2.06804 18.288 2.59388 19.542 3.52092 20.4691C4.44796 21.3961 5.70203 21.9219 7.01301 21.9333C8.32399 21.9447 9.58701 21.4408 10.53 20.53L12.24 18.82"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {copied ? "Copied!" : "Copy URL"}
      </button>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          window.location.href
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-2 px-4 py-2 rounded-md bg-secondary-800 hover:bg-secondary-700 flex-nowrap"
      >
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 8 19"
        >
          <path
            fillRule="evenodd"
            d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
            clipRule="evenodd"
          />
        </svg>
        Share on Facebook
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
          window.location.href
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-2 px-4 py-2 rounded-md bg-secondary-800 hover:bg-secondary-700 flex-nowrap"
      >
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 17"
        >
          <path
            fillRule="evenodd"
            d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
            clipRule="evenodd"
          />
        </svg>
        Share on X
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          window.location.href
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-2 px-4 py-2 rounded-md flex-nowrap bg-secondary-800 hover:bg-secondary-700"
      >
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.61856 1.69215C3.61856 2.6267 2.80852 3.3843 1.80928 3.3843C0.810041 3.3843 0 2.6267 0 1.69215C0 0.757601 0.810041 0 1.80928 0C2.80852 0 3.61856 0.757601 3.61856 1.69215Z"
            fill="currentColor"
          />
          <path
            d="M0.247423 4.6281H3.34021V14H0.247423V4.6281Z"
            fill="currentColor"
          />
          <path
            d="M8.31959 4.6281H5.2268V14H8.31959C8.31959 14 8.31959 11.0496 8.31959 9.20486C8.31959 8.09762 8.69766 6.98554 10.2062 6.98554C11.911 6.98554 11.9008 8.43453 11.8928 9.55709C11.8824 11.0244 11.9072 12.5219 11.9072 14H15V9.05372C14.9738 5.89538 14.1508 4.44008 11.4433 4.44008C9.8354 4.44008 8.83871 5.17006 8.31959 5.83049V4.6281Z"
            fill="currentColor"
          />
        </svg>
        Share on LinkedIn
      </a>
    </div>
  );
};

export default SocialShare;
