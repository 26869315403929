import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

const NotFound = ({ title, message, buttonText, buttonLink }) => {
  return (
	<div className="flex flex-col items-center justify-center min-h-screen gap-6 px-5 text-center">
	  <div className="flex flex-col max-w-sm">
		<h1 className="text-5xl font-extrabold leading-tight font-condensed">{title}</h1>
		<p className="text-xl leading-tight">
		  {message}
		</p>
	  </div>
	  <Link to={buttonLink}>
		<Button>{buttonText}</Button>
	  </Link>
	</div>
  );
};

export default NotFound;