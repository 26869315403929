import React from "react";
import MainShell from "../components/MainShell";

const AccountDeletion = () => {
  return (
    <MainShell className="min-h-screen pt-8 pb-4">
        <h1 className="text-3xl font-bold">
          Account and Data Deletion Request
        </h1>
      <div className="grid grid-cols-1 gap-8 pt-4 md:grid-cols-2">

        <div className="">
          <h2 className="mb-2 text-2xl font-semibold">
            1. Account Deletion Request
          </h2>
          <p className="mb-4 text-secondary-400">
            <strong>Instructions:</strong> To delete your account and all
            associated data, please email us at{" "}
            <a href="mailto:support@stridez.io" className="text-white">
              support@stridez.io
            </a>{" "}
            with the subject line ‘Account Deletion Request’. Include your
            username in the email. Our team will process your request within 30
            days.
          </p>
          <p className="text-secondary-400">
            <strong>Types of Data Deleted:</strong> Deleting your account will
            remove all your personal information, health and fitness data, and
            app activity logs from our system. Note that some data may be
            retained for legal or operational reasons for up to 90 days.
          </p>
        </div>

        <div>
          <h2 className="mb-2 text-2xl font-semibold">
            2. Partial Data Deletion Request
          </h2>
          <p className="mb-4 text-secondary-400">
            <strong>Instructions:</strong> If you prefer to delete only some of
            your data without deleting your entire account, please email us at{" "}
            <a href="mailto:support@stridez.io" className="text-white">
              support@stridez.io
            </a>{" "}
            with the subject line ‘Data Deletion Request’. Specify the types of
            data you want to delete, such as personal information or health and
            fitness data.
          </p>
          <p className="text-secondary-400">
            <strong>Types of Data Deleted:</strong> You can request the deletion
            of specific data types while retaining your account. Note that some
            data may be retained for legal or operational reasons for up to 90
            days.
          </p>
        </div>
      </div>
    </MainShell>
  );
};

export default AccountDeletion;
