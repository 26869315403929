import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import Layout from "./components/Layout";
import Blog from "./pages/Blog";
import BlogSingle from "./pages/BlogSingle";
import AccountDeletion from "./pages/AccountDeletion";
import Support from "./pages/Support";
import NotFound from "./pages/404";
import Plans from "./pages/Plans";
import { BlogProvider } from "./js/context/BlogProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect } from "react";
import { scrollTo } from "./js/utils";

const queryClient = new QueryClient();

function App() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {})
        .catch((error) => {});
    });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BlogProvider>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout><Home /></Layout>} />
            <Route path="*" element={<NotFound />} />
            <Route path="/support" element={<Layout><Support /></Layout>} />
            <Route path="/plans" element={<Layout><Plans /></Layout>} />
            <Route path="/account_deletion" element={<Layout><AccountDeletion /></Layout>} />
            <Route path="/blog" element={<Layout><Blog /></Layout>} />
            <Route path="/blog/:url" element={<Layout><BlogSingle /></Layout>} />
          </Routes>
        </Router>
      </BlogProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    scrollTo();
  }, [location]);

  return null;
}

export default App;
