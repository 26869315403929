import React from "react";
import { Link } from "react-router-dom";

const BlogItem = ({ image, title, description, url }) => {
  return (
    <div className="flex flex-col w-full gap-4">
      <Link className="overflow-hidden" to={`/blog/${url}`}>
        <img className="object-cover transition-transform duration-300 aspect-video hover:scale-110" src={image?.url} alt={title} />
      </Link>
      <div className="flex flex-col gap-1">
        <Link to={`/blog/${url}`}>
          <h4 className="text-2xl font-bold leading-tight tracking-tight text-white">
            {title}
          </h4>
        </Link>
        <p className="text-lg leading-snug text-secondary-400 line-clamp-2">{description}</p>
      </div>
    </div>
  );
};

export default BlogItem;
