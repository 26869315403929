import React from "react";
import Button from "../components/Button";

const Custom404 = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-6 px-5 text-center">
      <div className="flex flex-col max-w-sm">
        <h1 className="text-5xl font-extrabold leading-tight font-condensed">404 Not Found</h1>
        <p className="text-xl leading-tight">
          The page you are looking for could not be found
        </p>
      </div>
      <a href="/">
        <Button>Go back to the homepage</Button>
      </a>
    </div>
  );
};

export default Custom404;
