import React from "react";
import MainShell from "../components/MainShell";
import ContactUs from "../components/ContactUs";

const Support = () => {
  return (
    <MainShell className="min-h-screen pt-8 pb-4">
      <div className="grid grid-cols-1 gap-x-12 gap-y-6 md:grid-cols-2">
        <div className="flex flex-col max-w-xl gap-1">
          <p className="text-lg font-semibold uppercase text-secondary-300">Contact Us</p>
          <div className="flex flex-col gap-4">
            <h2 className="max-w-md text-4xl font-extrabold font-condensed md:text-5xl">
              Have a question? Ask us!
            </h2>
            <p className="text-lg leading-normal md:text-xl text-secondary-300">
              To ensure your satisfaction, please do not hesitate to reach out
              to us with any questions or concerns through the provided contact
              form or directly at:{" "}
              <a href="mailto:support@stridez.io" className="underline text-secondary-200">
                support@stridez.io
              </a>
            </p>
          </div>
        </div>
        <ContactUs />
      </div>
    </MainShell>
  );
};

export default Support;
