import React, { useState, useEffect } from "react";

const CachedImage = ({ src, alt, className, loading = "eager" }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      if ("caches" in window) {
        const cache = await caches.open("image-cache");
        let cachedResponse = await cache.match(src);

        if (!cachedResponse) {
          const response = await fetch(src);
          if (response.ok) {
            await cache.put(src, response.clone());
            cachedResponse = response;
          }
        }

        if (cachedResponse) {
          const blob = await cachedResponse.blob();
          setImageSrc(URL.createObjectURL(blob));
        }
      } else {
        // Fallback for browsers that don't support Cache API
        setImageSrc(src);
      }
    };

    fetchImage();
  }, [src]);

  return imageSrc ? (
    <img src={imageSrc} loading={loading} className={className} alt={alt} />
  ) : null;
};

export default CachedImage;
