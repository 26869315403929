import React, { useState } from "react";

function Button({
  variant,
  className,
  isLoading,
  disabled,
  children,
  isSmall,
  onClick,
  animation,
  hoverAnimation,
  type = "button",
  ...props
}) {
  const isSecondary = variant === "secondary";
  const [isHovered, setIsHovered] = useState(false);

  const buttonClass = isSecondary
    ? "text-white bg-secondary-800"
    : "text-black bg-primary";

  return (
    <button
      className={`relative flex items-center justify-center gap-2 px-5 ${
        isSmall ? "py-2 font-semibold" : "py-3 font-bold"
      } text-base rounded-lg whitespace-nowrap transition-all duration-300 cursor-pointer overflow-hidden ${buttonClass} ${className}`}
      disabled={disabled}
      type={type}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      {isLoading && <span className="button-loader" />}
      <span className="relative z-10">{children}</span>{" "}
      <div className="absolute inset-0 z-0 pointer-events-none">
        {" "}
        {animation && (
          <div className="flex items-center justify-center fixed-delay-skew-animation">
            <div
              className={`w-8 h-14 transform -skew-x-12 ${
                isSecondary ? "bg-secondary-700" : "bg-primaryHover"
              }`}
            />
          </div>
        )}
        {hoverAnimation && isHovered && (
          <div className="flex items-center justify-center fixed-delay-skew-animation">
            <div
              className={`w-8 h-14 transform -skew-x-12 ${
                isSecondary ? "bg-secondary-700" : "bg-primaryHover"
              }`}
            />
          </div>
        )}
      </div>
    </button>
  );
}

export default Button;
