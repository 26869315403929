import React from "react";
import { ReactComponent as DiscordIcon } from "../icons/discord.svg";
import Button from "../components/Button";
import SectionHeader from "./SectionHeader";

function DiscordSection() {
  return (
    <div className="flex flex-col items-center justify-center max-w-xl gap-3 text-center">
      <DiscordIcon className="text-white size-16" />
      <SectionHeader
        heading="Discord Community"
        subheading="Meet fellow fitness enthusiasts, share your journey, and unlock exclusive content. Let's achieve our goals together!"
        containerLarge={true}
      />
      <a
        href="https://discord.gg/jq6nVSTRnV"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button variant="secondary" animation={true} className="mt-2">
          Join Community
        </Button>
      </a>
    </div>
  );
}

export default DiscordSection;
