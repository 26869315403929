import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../icons/back.svg";

const BackButton = ({className}) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      className={`flex items-center mb-3 ${className}`}
    >
      <BackIcon className="size-6" />
      <div className="text-xl font-semibold">Back</div>
    </button>
  );
};

export default BackButton;
