import React from "react";
import MainShell from "../components/MainShell";
import { useBlogs } from "../js/context/BlogProvider";
import BlogList from "../components/BlogList";
import NotFound from "../components/NotFound";

const Blog = () => {
  const { blogs, error, isLoading } = useBlogs();

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen gap-6 px-5 text-center">
        <div className="flex flex-col max-w-sm">
          <p className="text-xl leading-tight">Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <NotFound
        title="Error Loading Blogs"
        message="An error occurred while loading the blogs"
        buttonText="Go back to the homepage"
        buttonLink="/"
      />
    );
  }

  return (
    <MainShell className="min-h-screen pt-8 pb-20">
      <BlogList
        blogs={blogs}
        error={error}
        isLoading={isLoading}
        limit={5}
        heading="Blog"
        subheading="Explore our latest articles"
      />
    </MainShell>
  );
};

export default Blog;
