import React, { createContext, useContext } from "react";
import { useQuery } from "react-query";
import axios from "axios";

const BlogContext = createContext();

const fetchBlogs = async () => {
  const { data } = await axios.get(
    "https://xqa9-hmia-taqt.n7c.xano.io/api:qObl-cId/blog"
  );
  return data;
};

export const BlogProvider = ({ children }) => {
  const {
    data: blogs,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["blogs"],
    queryFn: fetchBlogs,
    // staleTime: Infinity,
  });

  return (
    <BlogContext.Provider value={{ blogs, error, isLoading }}>
      {children}
    </BlogContext.Provider>
  );
};

export const useBlogs = () => {
  return useContext(BlogContext);
};
